/* eslint-disable no-template-curly-in-string */
const baseURL = process.env.REACT_APP_BASE_URL;
const myHeroBackendURL = process.env.REACT_APP_MYHERO_BE_URL;
const hpxURL = process.env.REACT_APP_HPX_URL;
const hpxWebsite = process.env.REACT_APP_HPX_WEBSITE;
const zakatCalculatorURL = process.env.REACT_APP_ZAKAT_CALCULATOR_URL;
const zakatHandBookURL = process.env.REACT_APP_ZAKAT_HANDBOOK_URL;

export const apiUrls = {
  home: {
    getContent: baseURL + "/home?populate=*&locale=${locale}",
    getImage:
      baseURL +
      "/home" +
      "?locale=${locale}" +
      "&populate[0]=home_corporate_value_items.value_icon" +
      "&populate[1]=home_customer_testimony_item.photo" +
      "&populate[2]=home_product_items.icon",
    register: hpxURL,
    hpxWebsite: hpxWebsite,
  },
  product: {
    getProduct: myHeroBackendURL + "/produk/${id}",
    getProductChart:
      myHeroBackendURL +
      "/produk/nav3" +
      "?kinerja=${timeframe}" +
      "&produk_id=${id}",
  },
  about: {
    getContent:
      baseURL +
      "/about-uses" +
      "?locale=${locale}" +
      "&populate[0]=about_us_components" +
      "&populate[1]=about_us_components.image" +
      "&populate[2]=about_us_components.icon" +
      "&populate[3]=about_us_components.item" +
      "&populate[4]=about_us_components.item.photo" +
      "&populate[5]=about_us_components.dropdowns" +
      "&sort=id:asc" +
      "&&populate=about_us_components.dropdowns.icon",
  },
  insight: {
    getCategories:
      baseURL +
      "/categories" +
      "?populate[0]=pdf_url" +
      "&populate[1]=image_url" +
      "&sort=id:asc",
    getInsights:
      baseURL +
      "/insights" +
      "?populate[0]=thumbnail_url" +
      "&populate[1]=categories" +
      "&populate[2]=subcategories" +
      "&sort=updatedAt:desc" +
      "&pagination[pageSize]=100",
  },
  insightPdf: {
    getPdf:
      baseURL +
      "/insights/${id}" +
      "?populate[0]=pdf_url" +
      "&populate[1]=thumbnail_url" +
      "&populate[2]=categories" +
      "&populate[3]=subcategories",
  },
  shortlink: {
    getShortlink: baseURL + "/shortlinks/find/${shortlink}",
  },
  blog: {
    getBlogs: baseURL + "/blogs?populate[0]=image&sort=createdAt:desc",
  },
  blogBySlug: {
    getBlog: baseURL + "/blogs?filters[slug][$eq]=${slug}&populate=image",
  },
  sharia: {
    getContent:
      baseURL +
      "/home-syariah" +
      "?locale=${locale}" +
      "&populate[0]=download_photo" +
      "&populate[1]=zakat_saham_mechanism_item.image" +
      "&populate[2]=sazadah_image" +
      "&populate[3]=baznas_logo" +
      "&populate[4]=idx_logo" +
      "&populate[5]=mui_logo" +
      "&populate[6]=localizations" +
      "&populate[7]=product_image" +
      "&populate[8]=sazadah_logo",
    getZakatCalculatorURL: zakatCalculatorURL,
    getZakatHandBookURL: zakatHandBookURL,
  },
};
